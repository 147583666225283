<template>
  <b-modal
    class="bmodal"
    size="sm"
    id="churchSearchModal"
    ref="modalChurchSearch"
    :title="i18n['church-search'].tcChurchSearch"
    centered
    :hide-footer="matchingChurches.length > 0"
    @ok="handleOkClick($event)"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :cancel-title="i18n['church-search'].tcCancel"
    :ok-title="i18n['church-search'].tcOk"
  >
    <div class="formdiv" v-if="matchingChurches.length === 0">
      <b-form>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['church-search'].tcName }}
            <b-form-input v-model="name"></b-form-input>
          </b-col>
        </b-row>
        <!-- NOTE: Commented out because they weren't on the list but guessing they could comeback during testing
          <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            EMAIL
            <b-form-input v-model="email"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            PHONE
            <b-form-input v-model="phone"></b-form-input>
          </b-col>
        </b-row> -->
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['church-search'].tcCity }}
            <b-form-input v-model="address_city"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['church-search'].tcState }}
            <b-form-select v-if="isUSSelected" v-model="selected_state" :options="state_list"></b-form-select>
            <b-form-input v-if="!isUSSelected" v-model="foreign_state"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['church-search'].tcPostalCode }}
            <b-form-input v-model="postal_code"></b-form-input>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="noresults" v-if="showNoResults">{{ i18n['church-search'].tcNoMatchesWereFound }}</div>
    <div v-if="matchingChurches.length > 0">
      <b-button class="btn-tertiary btn-w-sm float-right upper" @click="modalBack()">{{
        i18n['church-search'].tcBack
      }}</b-button>
      <h5>
        <span class="upper">{{ i18n['church-search'].tcResults }} </span>
        <br />({{ i18n['church-search'].tcClickToSelect }})
      </h5>
      <div class="long_list">
        <ul>
          <li v-for="church in matchingChurches" :key="church.org_key">
            <div class="resultDiv" @click="handleSearchedChurchClick(church)">
              <b>{{ church.name_text }}</b>
              <div>{{ church.address_block }}</div>
              <div>{{ church.email_text }}</div>
              <div>{{ church.phone_text }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import USStateData from '@/json/states.json'

export default {
  name: 'church-search',
  props: {
    include_orgs_without_address: {
      type: Number,
      default: 1,
    },
    camp_country: {
      type: String,
      default: 'bc4b70f8-280e-4bb0-b935-9f728c50e183', // default to USA
    },
    organization_type_key: {
      type: String,
      default: 'b2ab90c2-f48d-4045-aa88-10ecf6a3a732', // default to church org type key
    },
    organization_typegroup: {
      type: String,
      default: 'a',
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'church-search': {
            tcBack: 'Back',
            tcCancel: 'Cancel',
            tcChurchSearch: 'Church Search',
            tcCity: 'City',
            tcClickToSelect: 'Click to select',
            tcName: 'Name',
            tcNoMatchesWereFound: 'No matches were found',
            tcOk: 'Ok',
            tcPostalCode: 'Postal Code',
            tcResults: 'Results',
            tcState: 'State',
          },
        }
      },
    },
  },
  data() {
    return {
      findReturn: null,
      country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      noCloseOnBackdrop: true,
      selected_gender: 'B25E4B70-3F72-48CF-B21E-61A860B36C6A',
      selected_country: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      selected_state: null,
      address_city: null,
      postal_code: null,
      name: null,
      foreign_state: null,
      country_list: [
        { value: 'fc4b70f8-280e-4bb0-b935-9f728c50e183', text: 'Germany' },
        { value: 'bc4b70f8-280e-4bb0-b935-9f728c50e183', text: 'United States' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      matchingChurches: 'search/matchingChurches',
    }),
    showNoResults() {
      return this.matchingChurches.length === 0 && !!this.findReturn
    },
    isUSSelected() {
      return !this.camp_country || this.camp_country.toLowerCase() === this.country_us
    },
    state_list() {
      return [...USStateData.state_list]
    },
  },
  methods: {
    ...mapActions({
      clearSearches: 'search/clearSearches',
      locateChurches: 'search/locateChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    modalBack() {
      this.findReturn = null
      this.clearSearches()
    },
    closeModal() {
      this.clearSearches()
      this.$refs.modalChurchSearch.hide()
    },
    handleSearchedChurchClick(ch) {
      this.$emit('selectionMade', ch)
      this.closeModal()
    },
    async handleOkClick(e) {
      e.preventDefault()
      // use the input to find a church
      // display the results and allow selection
      // emit the selection
      try {
        this.findReturn = null
        this.setLoadingStatus(true)
        await Promise.all([
          await this.locateChurches({
            name: this.name,
            address_cnt_key: this.camp_country,
            address_city: this.address_city,
            address_postal_code: this.postal_code,
            address_state: this.selected_state || this.foreign_state,
            address_postal_code: this.address_postal_code,
            include_orgs_without_address: this.include_orgs_without_address,
            organization_type_key: this.organization_type_key,
            organization_typegroup: this.organization_typegroup,
          }),
        ]).then((rtn) => (this.findReturn = rtn))
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
}
</script>

<style scoped>
.noresults {
  color: #dd0000;
  font-weight: 600;
}
.resultDiv {
  cursor: pointer;
}
.resultDiv:hover {
  background-color: gainsboro;
}
.lh42 {
  margin-bottom: 6px;
}
.long_list {
  margin: 20px 8px;
  max-height: 398px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.long_list ul li {
  font-size: 13px;
  margin-bottom: 10px;
}
.font-style-3 {
  font-weight: 600;
}
.upper {
  text-transform: uppercase;
}
.formdiv {
  text-transform: uppercase;
}
</style>
