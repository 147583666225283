<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ title }}</h1>
      <b-form validated>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcName }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcMemberName">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
            </header>
            <div v-if="Section1">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-1" :label="`${translations.tcPrefix}:`" label-for="prefix-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="prefix-select" :title="translations.tcPrefix" :options="optionsPrefix"
                          v-model="individual.prefix_key" class="g-select flex-0 mr-3 mb-3">
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-1" :label="`${translations.tcFirstName}:`"
                        label-for="first-name-input" class="form-element" label-class="form-group-label">
                        <template slot="label" class="form-group-label"> {{ translations.tcFirstName }}: </template>
                        <b-form-input :title="translations.tcFirstName" id="first-name-input"
                          :placeholder="translations.tcFirstName" v-model="individual.first_name" required trim>
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-2" :label="`${translations.tcMiddleName}:`"
                        label-for="middle-name-input" class="form-element" label-class="form-group-label">
                        <b-form-input :title="translations.tcMiddleName" id="middle-name-input"
                          :placeholder="translations.tcMiddleName" v-model="individual.middle_name" trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-3" :label="`${translations.tcLastName}:`"
                        label-for="last-name-input" class="form-element" label-class="form-group-label">
                        <b-form-input :title="`${translations.tcLastName}:`" id="last-name-input"
                          :placeholder="translations.tcLastName" v-model="individual.last_name" disabled></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="individual.gender === 'Male'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-2" :label="`${translations.tcSuffix}:`" label-for="suffix-select"
                        label-class="form-group-label" class="form-element">
                        <b-form-select id="suffix-select" :title="`${translations.tcSuffix}:`" :options="optionsSuffix"
                          v-model="individual.suffix_key" class="g-select flex-0 mr-3 mb-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-4" :label="`${translations.tcFormalName}:`"
                        label-for="formal-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="`${translations.tcFormalName}:`" id="formal-name-input"
                          :placeholder="translations.tcFormalName" v-model="individual.formal_name"
                          disabled></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="input-group-5" :label="`${translations.tcInformalName}:`"
                        label-for="informal-name-input" label-class="form-group-label" class="form-element">
                        <b-form-input :title="`${translations.tcInformalName}:`" id="informal-name-input"
                          :placeholder="translations.tcInformalName" v-model="individual.informal_name"
                          trim></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-3" :label="`${translations.tcPreferredName}:`"
                        label-for="preferred-select" label-class="form-group-label" class="form-element">
                        <b-form-select id="preferred-select" :title="`${translations.tcPreferredName}:`"
                          :options="optionsPreferredName" v-model="individual.preferred_name"
                          class="g-select flex-0 mr-3 mb-3"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="this.individual.individual_type === 'Non-Member'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-9" :label="`${translations.tcBirthDate}:`" label-for="Birthdate"
                        label-class="form-group-label" class="form-element">
                        <b-form-datepicker id="Birthdate" v-model="individual.birthdate" class="mb-2 contact_date"
                          :locale="prefCulture" :label-help="translations.tcCalHelp"
                          :label-no-date-selected="translations.tcDatePickerPlaceholder"
                          :placeholder="translations.tcDatePickerPlaceholder" :show-decade-nav="showDecadeNav"
                          :hide-header="hideHeader">
                          <template #button-content style="padding: 12px">
                            <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                          </template>
                        </b-form-datepicker>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4" v-if="showSpouse">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcSpouseInformation }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcSpouseInformation">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="12" class="font-style-3">
                  <span class="form-group-label mr-4" v-if="individual.spouse_ind_key">{{
                    individual.spouse_name
                  }}</span>
                  <b-button v-if="individual.spouse_ind_key" variant="primary" class="flex-1 btn-pl-xsm"
                    @click.prevent="clearSpouse()">{{ translations.tcClear }}</b-button>

                  <button v-if="!individual.spouse_ind_key" @click="spouseClick(individual.ind_key)"
                    class="btn btn-primary bottom-m-20">
                    {{ translations.tcAddSpouse }}
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">
                {{ translations.tcAdditionalInformation }}
                <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcAdditionalInformation">
                  <i-tooltip />
                </span>
              </h2>
              <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
            </header>
            <div v-if="Section2">
              <b-row>
                <b-col sm="6"
                  v-if="this.individual.individual_type !== 'Non-Member' || this.individual.friend_church_key">
                  <b-row>
                    <b-col sm="6" class="font-style-3">
                      <b-form-group id="select-group-8" :label="`${translations.tcRecommendedBy}:`"
                        label-for="recommendedby-select" label-class="form-group-label" class="form-element"
                        v-if="individual.individual_type === 'Candidate'">
                        <b-form-select id="recommendedby-select" :title="`${translations.tcRecommendedBy}:`"
                          v-model="individual.recommended_by" :options="optionsRecommendedBy"
                          class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                      </b-form-group>
                      <b-form-group id="select-recommended-member" class="form-element"
                        v-if="individual.individual_type === 'Candidate' && individual.recommended_by === 0">
                        <b-form-select id="recommended-member-select" :title="`${translations.tcMember}`"
                          v-model="individual.recommended_ind_key" :options="[...searchedMember]"
                          class="form-control col-left-input g-select flex-0 mr-3 mb-3" required></b-form-select>
                        <b-button :name="`findRecommendedByMemberButton`" variant="primary" class="flex-1 btn-pl-xsm"
                          @click="showModal($event, 'personSearchModal')">{{ translations.tcFind }}</b-button>
                      </b-form-group>
                      <template v-if="individual.individual_type === 'Candidate' && individual.recommended_by === 1">
                        <template v-if="!createPastor">
                          <b-form-group id="select-recommended-pastor" class="form-element">
                            <b-form-select id="recommended-pastor-select" :title="translations.tcPastor"
                              v-model="individual.recommended_ind_key" :options="[...searchedPastor]"
                              class="form-control col-left-input g-select flex-0 mr-3 mb-3" required>
                              <template slot="first">
                                <option :value="null" disabled>{{ translations.tcSelectPastor }}</option>
                              </template>
                            </b-form-select>
                            <b-button :name="`findRecommendedByPastorButton`" variant="primary"
                              class="flex-1 btn-pl-xsm" @click="createNewPastor()">{{
                                translations.tcCreatePastor
                              }}</b-button>
                          </b-form-group>
                        </template>
                        <template v-if="createPastor">
                          <b-form-group id="select-group-20" :label="`${translations.tcPrefix}:`"
                            label-for="pastor-prefix-select" label-class="form-group-label" class="form-element">
                            <b-form-select id="pastor-prefix-select" :title="translations.tcPrefix"
                              :options="optionsPrefixPastor" v-model="individual.pastor_prefix_key"
                              class="g-select flex-0">
                            </b-form-select>
                          </b-form-group>
                          <b-form-group id="input-group-21" :label="`${translations.tcFirstName}:`"
                            label-for="pastor-first-name-input" label-class="form-group-label" class="form-element">
                            <b-form-input :title="translations.tcFirstName" id="pastor-first-name-input"
                              :placeholder="translations.tcFirstName" v-model="individual.pastor_first_name" required>
                            </b-form-input>
                          </b-form-group>
                          <b-form-group id="input-group-22" :label="`${translations.tcLastName}:`"
                            label-for="pastor-last-name-input" label-class="form-group-label" class="form-element mb-5">
                            <b-form-input :title="translations.tcLastName" id="pastor-last-name-input"
                              :placeholder="translations.tcLastName" v-model="individual.pastor_last_name" trim>
                            </b-form-input>
                          </b-form-group>
                        </template>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <template v-if="!createChurch">
                        <b-form-group id="select-group-4" :label="`${translations.tcChurchMembership}`"
                          label-for="church-select" label-class="form-group-label" class="form-element">
                          <b-form-select id="church-select" :title="`${translations.tcChurchMembership}`"
                            v-model="individual.church_key" :options="searchedChurch" class="g-select flex-0 mr-3 mb-3"
                            required></b-form-select>
                          <b-button :name="`findChurchButton`" variant="primary" class="flex-1 btn-pl-xsm mr-3"
                            @click="showModal($event, 'churchSearchModal')">{{ translations.tcFind }}</b-button>
                          <b-button :name="`createChurchButton`" variant="primary" class="flex-1 btn-pl-xsm"
                            @click="createNewChurch()">
                            {{ translations.tcCreateChurch }}
                          </b-button>
                        </b-form-group>
                      </template>
                      <template v-if="createChurch">
                        <b-form-group id="input-group-30" :label="`${translations.tcChurchName}:`"
                          label-for="church-name-input" label-class="form-group-label" class="form-element">
                          <b-form-input :title="`${translations.tcChurchName}:`" id="church-name-input"
                            :placeholder="`${translations.tcChurchName}`" v-model="individual.church_name"
                            required></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-addrch" :label="`${translations.tcAddress1}:`"
                          label-for="church-address1-input" label-class="form-group-label" class="form-element">
                          <b-form-input :title="`${translations.tcAddress1}`" id="church-address1-input"
                            :placeholder="`${translations.tcAddress1}`" v-model="individual.church_address1"
                            trim></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-citych" :label="`${translations.tcCity}:`"
                          label-for="church-city-input" label-class="form-group-label" class="form-element">
                          <b-form-input :title="`${translations.tcCity}`" id="church-city-input"
                            :placeholder="`${translations.tcCity}`" v-model="individual.church_city"
                            trim></b-form-input>
                        </b-form-group>
                        <b-form-group id="select-group-statech" :label="`${translations.tcState}:`"
                          label-for="church-state-select" label-class="form-group-label" class="form-element"
                          v-if="individual.church_cnt_key === usCountry && createChurch">
                          <b-form-select id="church-state-select" :title="`${translations.tcState}:`"
                            :options="optionsStates" v-model="individual.church_state"
                            class="g-select flex-0 mr-3 mb-3"></b-form-select>
                        </b-form-group>
                        <b-form-group id="select-group-state-provincech" :label="`${translations.tcStateOrProvince}:`"
                          label-for="church-state-province-select" label-class="form-group-label" class="form-element"
                          v-if="individual.church_cnt_key !== usCountry && createChurch">
                          <b-form-input :title="`${translations.tcStateOrProvince}:`" id="church-state-province-input"
                            :placeholder="`${translations.tcStateOrProvince}`" v-model="individual.church_state"
                            trim></b-form-input>
                        </b-form-group>
                        <b-form-group id="input-group-postal-codech" :label="`${translations.tcPostalCode}:`"
                          label-for="church-postal-code-input" label-class="form-group-label" class="form-element">
                          <b-form-input :title="translations.tcPostalCode" id="church-postal-code-input"
                            :placeholder="`${translations.tcPostalCode}`" v-model="individual.church_postal_code" trim>
                          </b-form-input>
                        </b-form-group>
                        <b-form-group id="select-group-countrych" :label="`${translations.tcCountry}:`"
                          label-for="church-country-select" label-class="form-group-label" class="form-element">
                          <b-form-select id="church-country-select" :title="`${translations.tcCountry}:`"
                            :options="optionsCountries" v-model="individual.church_cnt_key"
                            class="g-select flex-0 mr-3 mb-5"></b-form-select>
                        </b-form-group>
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-5" :label="`${translations.tcDenomination}:`"
                        label-for="denomination-select" label-class="form-group-label" class="form-element">
                        <b-form-select id="denomination-select" :title="`${translations.tcDenomination}`"
                          :options="optionsDenomination" v-model="individual.denomination_key"
                          class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="individual.gender === 'Male'">
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="select-group-6" :label="`${translations.tcOccupation}:`"
                        label-for="occupation-select" label-class="form-group-label" class="form-element">
                        <b-form-select id="occupation-select" :title="`${translations.tcOccupation}:`"
                          :options="optionsOccupationTranslated" v-model="individual.occupation_key"
                          class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group id="text-group-1" :label="`${translations.tcOtherInformation}:`"
                        label-for="comment-textarea" label-class="form-group-label" class="form-element">
                        <b-form-textarea id="comment-textarea" v-model="individual.comment"
                          :placeholder="`${translations.tcOtherInformation}`" rows="4" max-rows="6"
                          trim="trim"></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleSaveClick()" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcSave }}
              </b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">
                {{ translations.tcCancel }}
              </b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :members_only_flag="0" :not_a_member="0"
      :camp_country="campCountryKey"></PersonSearch>
    <ChurchSearch @selectionMade="applyFoundChurch($event)" :camp_country="campCountryKey"
      :i18n="translations.components"></ChurchSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AppDatePicker from '@/components/AppDatePicker.vue'
import ChurchSearch from '@/components/ChurchSearch.vue'
import constantData from '@/json/data.json'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'individual-details-edit',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      Section1: true,
      Section2: true,
      Section3: true,
      trim: true,
      createPastor: false,
      createChurch: false,
      findButton: '',
      optionsOccupationTranslated: [],
      searchedSpouse: [],
      searchedMember: [],
      searchedPastor: [],
      searchedChurch: [],
      campCountryKey: constantData.USA_country,
      usCountry: constantData.USA_country,
      errMessage: [],
      constant_irt_key_pastor: '6410898a-1906-4427-b6ca-ce5fe167ade4',
      constant_irt_key_camp_member: 'ca784c8b-bdeb-4909-8a02-3abfd408258b',
      individual: {
        birthdate: null,
        camp_key: null,
        church_address1: '',
        church_city: '',
        church_cnt_key: null,
        church_key: null,
        church_name: '',
        church_postal_code: '',
        church_state: '',
        comment: '',
        denomination_key: null,
        first_name: '',
        formal_name: '',
        friend_church_key: null,
        friend_church_name: '',
        friend_key: null,
        gender: 'Male',
        ind_key: null,
        individual_type: 'Member',
        informal_name: '',
        language_key: null,
        last_name: '',
        member_type_key: '',
        middle_name: '',
        occupation_key: null,
        original_spouse_ind_key: null,
        pastor_first_name: '',
        pastor_last_name: '',
        pastor_prefix_key: null,
        preferred_name: 'F',
        prefix_key: null,
        prospect_key: null,
        recommended_by: 0,
        recommended_ind_key: null,
        recommended_ind_name: '',
        recommended_ind_type: null,
        related_organization_chu_key: null,
        related_organization_key: null,
        related_organization: '',
        relationship_type_key: null,
        relationship_type_name: '',
        spouse_ind_key: null,
        spouse_name: null,
        suffix_key: null,
      },
    }
  },
  methods: {
    ...mapActions({
      clearProspectProfile: 'membership/clearProspectProfile',
      loadIndividualEditFormOptions: 'membership/loadIndividualEditFormOptions',
      loadIndividualProfile: 'membership/loadIndividualProfile',
      loadMembersByCamp: 'membership/loadMembersByCamp',
      loadProspectFormOptions: 'membership/loadProspectFormOptions',
      saveIndividualDetails: 'membership/saveIndividualDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
    }),
    async pageLoad() {
      if (this.userSelectedIndividualKey) {
        await this.loadIndividualProfile(this.userSelectedIndividualKey)
      }
      Promise.all([
        this.loadIndividualEditFormOptions(this.individualProfile.ind_key),
        this.loadProspectFormOptions(this.userCampKey),
        !this.individualProfile.prospect_camp_key
          ? null
          : this.loadMembersByCamp(this.individualProfile.prospect_camp_key),
      ])
        .then(() => {
          this.individual.ind_key = this.individualProfile.ind_key
          this.individual.prefix_key = this.individualProfile.prefix_key
          this.individual.first_name = this.individualProfile.first_name
          this.individual.middle_name = this.individualProfile.middle_name
          this.individual.last_name = this.individualProfile.last_name
          this.individual.suffix_key = this.individualProfile.suffix_key
          this.individual.formal_name = this.individualProfile.formal_name
          this.individual.informal_name = this.individualProfile.informal_name
          this.individual.preferred_name = this.individualProfile.preferred_name
          this.individual.church_key = this.individualProfile.church_key
          this.individual.camp_key = this.individualProfile.camp_key
          this.individual.denomination_key = this.individualProfile.denomination_key
          this.individual.occupation_key = this.individualProfile.occupation_key
          this.individual.language_key = this.individualProfile.language_key
          this.individual.comment = this.individualProfile.comment
          this.individual.gender = this.individualProfile.gender
          this.individual.birthdate = this.individualProfile.birthdate
          this.individual.spouse_ind_key = this.individualProfile.spouse_ind_key
          this.individual.spouse_name = this.individualProfile.spouse_formal_name
          this.individual.original_spouse_ind_key = this.individualProfile.spouse_ind_key
          if (!!this.individualProfile.spouse_ind_key) {
            this.searchedSpouse.push({
              value: this.individualProfile.spouse_ind_key,
              text: this.individualProfile.spouse_formal_name,
            })
          }
          if (this.individualProfile.member_type_key === null && this.individualProfile.prospect_key === null) {
            this.individual.individual_type = 'Non-Member'
          } else if (this.individualProfile.member_type_key === null && this.individualProfile.prospect_key !== null) {
            this.individual.individual_type = 'Candidate'
          }
          this.individual.church_cnt_key = this.convertedProspectFormOptions.countriesByCamp[0].value
          this.individual.member_type_key = this.individualProfile.member_type_key
          this.individual.prospect_key = this.individualProfile.prospect_key
          this.searchedMember = [{ value: null, text: 'Select a member', disabled: true }, ...this.optionsMembers]
          this.searchedPastor = [...this.optionsRecommendedByPastor]
          this.searchedChurch = [...this.optionsChurch]
          if (this.individual.individual_type === 'Candidate') {
            this.individual.recommended_by = this.setRecommendedBy()
            this.individual.recommended_ind_name = this.individualProfile.referral_formal_name
            this.individual.recommended_ind_type = this.individual.recommended_by
              ? this.constant_irt_key_pastor
              : this.constant_irt_key_camp_member
            if (this.shouldAddPastor()) {
              // it's a pastor and may not be in the list so add them
              this.searchedPastor.push({
                value: this.individualProfile.referral_ind_key,
                text: this.individualProfile.referral_formal_name,
              })
            }
            this.individual.recommended_ind_key = this.individualProfile.referral_ind_key
            this.individual.camp_key = this.individualProfile.prospect_camp_key
          }
          if (this.individual.individual_type === 'Non-Member' && this.individualProfile.friend_church_key) {
            this.individual.friend_church_key = this.individualProfile.friend_church_key
            this.individual.friend_church_name = this.individualProfile.friend_church_name
            this.individual.friend_key = this.individualProfile.friend_key
            this.individual.relationship_type_name = this.individualProfile.relationship_type_name
            this.individual.relationship_type_key = this.individualProfile.relationship_type_key
            this.individual.related_organization = this.individualProfile.related_organization
            this.individual.related_organization_key = this.individualProfile.related_organization_key
            this.individual.related_organization_chu_key = this.individualProfile.related_organization_chu_key
            // nonmember friend of church won't have a church key on the individual profile, so use the friend's church key
            this.individual.church_key = this.individualProfile.friend_church_key
          }
          if (this.shouldAddChurch()) {
            this.searchedChurch.push({
              value: this.individualProfile.church_key,
              text: this.individualProfile.church_name,
            })
          }
          this.campCountryKey = !!this.convertedProspectFormOptions.countriesByCamp[0]
            ? this.convertedProspectFormOptions.countriesByCamp[0].value
            : this.usCountry
          this.clearProspectProfile()

          const disabledPlaceholder = this.translations.tcSelectOccupation
          this.translateDropdownOptions([{ node: 'occupation-type', store: 'optionsOccupation', disabledPlaceholder }])
        })
        .then(() => {
          this.setLoadingStatus(false)
        })
    },
    async shouldAddPastor() {
      let foundPastor = this.searchedPastor.findIndex((p) => p.value === this.individualProfile.referral_ind_key)
      let didWeFind = foundPastor < 0 && this.individual.recommended_ind_type === this.constant_irt_key_pastor

      return foundPastor < 0 && this.individual.recommended_ind_type === this.constant_irt_key_pastor
    },
    async shouldAddChurch() {
      let foundChurch = this.searchedChurch.findIndex((c) => c.value === this.individualProfile.church_key)

      return foundChurch < 0
    },
    createNewPastor() {
      this.individual.recommended_ind_key = null
      this.createPastor = true
    },
    createNewChurch() {
      this.individual.church_key = null
      this.createChurch = true
    },
    async handleCancelClick() {
      this.routeToDetails()
    },
    async handleSaveClick() {
      if (this.validateForm()) {
        var payload = {
          user_ind_key: this.userIndKey,
          individual_type: this.individual.individual_type,
          ind_key: this.individual.ind_key,
          prefix_key: this.individual.prefix_key,
          first_name: this.individual.first_name,
          middle_name: this.individual.middle_name,
          last_name: this.individual.last_name,
          suffix_key: this.individual.suffix_key,
          formal_name: this.individual.formal_name,
          informal_name: this.individual.informal_name,
          preferred_name: this.individual.preferred_name,
          camp_key: this.individual.camp_key,
          church_key: this.individual.church_key,
          church_name: this.individual.church_name,
          church_address1: this.individual.church_address1,
          church_city: this.individual.church_city,
          church_state: this.individual.church_state,
          church_postal_code: this.individual.church_postal_code,
          church_cnt_key: this.individual.church_cnt_key,
          denomination_key: this.individual.denomination_key,
          occupation_key: this.individual.occupation_key,
          language_key: this.individual.language_key,
          comment: this.individual.comment,
          friend_key: this.individual.friend_key,
          gender: this.individual.gender,
          birthdate: this.individual.birthdate,
          original_spouse_ind_key: this.individual.original_spouse_ind_key,
          spouse_ind_key: this.individual.spouse_ind_key,
          spouse_name: this.individual.spouse_ind_key ? this.getSpouseName(this.individual.spouse_ind_key) : null,
          recommended_ind_name: this.individual.recommended_ind_key
            ? this.getRecommendedName(this.individual.recommended_ind_key)
            : null,
          recommended_ind_key: this.individual.recommended_ind_key,
          recommended_ind_type: this.getRecommendedIndType(),
          member_type_key: this.individual.member_type_key,
          prospect_key: this.individual.prospect_key,
          pastor_prefix_key: this.individual.pastor_prefix_key,
          pastor_first_name: this.individual.pastor_first_name,
          pastor_last_name: this.individual.pastor_last_name,
        }
        let result = true
        await Promise.all([this.setLoadingStatus(true), (result = await this.saveIndividualDetails(payload))]).then(
          () => {
            if (result.data && result.data.response === '0') {
              console.error('errormessage: ', this.errMessage)
              this.errMessage = result.data.message
              console.error('errormessage: ', this.errMessage)
              result = false
            }
            this.setLoadingStatus(false)
            this.$swal({
              icon: result ? 'success' : 'error',
              text: result ? this.translations.tcIndividualWasSaved : this.translations.tcErrorDuringSave,
              confirmButtonText: this.translations.tcOk || 'Ok',
            }).then(async (confirm) => {
              if (confirm.value === true) {
                this.setLoadingStatus(true)
                await this.loadIndividualProfile().then(() => {
                  this.setLoadingStatus(false)
                  this.routeToDetails()
                })
              }
            })
          }
        )
      }
    },
    validateForm() {
      let rtn = true
      if (this.individual.first_name === '') {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorFirstNameRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      if (this.createChurch && this.individual.church_name === '') {
        rtn = false
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorChurchNameRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        })
      }
      return rtn
    },
    async spouseClick(data) {
      this.setRouterBackLink(this.$route.name)
      this.$router.push({ name: 'add-spouse' })
    },
    setRecommendedBy() {
      const foundInMembers =
        this.campMembers.filter((member) => member.ind_key === this.individualProfile.referral_ind_key).length > 0
      return foundInMembers ? 0 : 1
    },
    getRecommendedName(ind_key) {
      if (this.individual.recommended_by === 0) {
        const ref = this.searchedMember.filter((member) => member.value === ind_key)
        let refName = ref[0].text
        return refName
      } else {
        const ref = this.searchedPastor.filter((pastor) => pastor.value === ind_key)
        let refName = ref[0].text
        return refName
      }
    },
    getRecommendedIndType() {
      if (this.individual.recommended_by === 0) {
        const ref = this.convertedindividualEditFormOptions.referralTypes.filter(
          (x) => x.text === 'constant_irt_key_camp_member'
        )
        let refType = ref[0].value
        return refType
      } else {
        const ref = this.convertedindividualEditFormOptions.referralTypes.filter(
          (x) => x.text === 'constant_irt_key_pastor'
        )
        let refType = ref[0].value
        return refType
      }
    },
    getSpouseName(ind_key) {
      const ref = this.searchedSpouse.filter((spouse) => spouse.value === ind_key)
      let refName = ref[0].text
      return refName
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'findSpouseButton':
          this.searchedSpouse.push({ value: evt.ind_key, text: evt.name_text })
          this.individual.spouse_ind_key = evt.ind_key
          break
        case 'findRecommendedByMemberButton':
          this.searchedMember.push({ value: evt.ind_key, text: evt.name_text })
          this.individual.recommended_ind_key = evt.ind_key
          break
        case 'findRecommendedByPastorButton':
          this.searchedPastor.push({ value: evt.ind_key, text: evt.name_text })
          this.individual.recommended_ind_key = evt.ind_key
          break
        default:
          console.error('Default person, why are we here?')
          break
      }
    },
    clearSpouse() {
      this.individual.spouse_ind_key = null
      this.individual.spouse_name = ''
    },
    async applyFoundChurch(evt) {
      this.searchedChurch.push({ value: evt.org_key, text: evt.name_text })
      this.individual.church_key = evt.org_key
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    routeToDetails() {
      switch (this.individual.individual_type) {
        case 'Non-Member':
          this.$router.push({ name: 'non-member-details' })
          break
        case 'Candidate':
          this.$router.push({ name: 'prospect-details' })
          break
        default:
          //defaults to member
          this.$router.push({ name: 'member-details' })
          break
      }
    },
  },
  async created() {
    //need a selected individual or redirect back to membership landing
    if (!this.userSelectedIndividualKey || !this.individualProfile) {
      this.$router.push('/programs/mb/membership/')
    }

    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('church-search'),
        this.getComponentTranslations('common.occupation-type'),
      ]).then((results) => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common },
        }
        this.$set(this.translations, 'components', translatedText)

        this.pageLoad()
      })
    } catch (e) {
      console.error('Error in IndividualDetailsEdit.vue, created()', e)
    } finally {
    }
  },
  computed: {
    ...mapGetters({
      campMembers: 'membership/members',
      convertedindividualEditFormOptions: 'membership/convertedindividualEditFormOptions',
      convertedProspectFormOptions: 'membership/convertedProspectFormOptions',
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
    }),

    // FormOptions
    optionsPrefixMale() {
      return [...this.convertedindividualEditFormOptions.prefixMale]
    },
    optionsPrefixFemale() {
      return [...this.convertedindividualEditFormOptions.prefixFemale]
    },
    optionsPrefix() {
      let options = [{ value: null, text: this.translations.tcSelectPrefix, disabled: true }]
      if (this.individual.gender === 'Male') {
        options = [...options, ...this.optionsPrefixMale]
      } else if (this.individual.gender === 'Female') {
        options = [
          ...options,
          ...this.optionsPrefixFemale.map((x) => {
            return {
              text: !!this.translations[x.sitecore] ? this.translations[x.sitecore] : x.text,
              value: x.value,
            }
          }),
        ]
      } else {
        // null value
        options = [
          ...options,
          ...this.optionsPrefixMale,
          ...this.optionsPrefixFemale.map((x) => {
            return {
              text: !!this.translations[x.sitecore] ? this.translations[x.sitecore] : x.text,
              value: x.value,
            }
          }),
        ]
      }
      return options
    },
    optionsSuffix() {
      return [
        { value: null, text: this.translations.tcSelectSuffix, disabled: true },
        ...this.convertedindividualEditFormOptions.suffixMale,
      ]
    },
    optionsPrefixPastor() {
      return [
        { value: null, text: this.translations.tcSelectSuffix, disabled: true },
        ...this.convertedindividualEditFormOptions.prefixMale,
        ...this.convertedindividualEditFormOptions.prefixFemale,
      ]
    },
    optionsChurch() {
      return [
        { value: null, text: this.translations.tcSelectChurch, disabled: true },
        ...this.convertedindividualEditFormOptions.churches,
      ]
    },
    optionsDenomination() {
      return [
        { value: null, text: this.translations.tcSelectDenomination, disabled: true },
        ...this.convertedindividualEditFormOptions.denominations.map((x) => {
          return {
            value: x.value,
            text: !!this.translations[x.sitecore] ? this.translations[x.sitecore] : x.text,
          }
        }),
      ]
    },
    optionsOccupation() {
      return [
        ...this.convertedindividualEditFormOptions.occupations.map((x) => {
          return {
            value: x.value,
            text: !!this.translations[x.sitecore] ? this.translations[x.sitecore] : x.text,
          }
        }),
      ]
    },
    optionsLanguage() {
      return [
        { value: null, text: this.translations.tcSelectLanguage, disabled: true },
        ...this.convertedindividualEditFormOptions.languages,
      ]
    },
    optionsRecommendedBy() {
      return [
        { value: 0, text: this.translations.tcMember },
        { value: 1, text: this.translations.tcPastor },
      ]
    },
    optionsRecommendedByMembers() {
      return [{ value: null, text: this.translations.tcSelectMember, disabled: true }, ...this.optionsMembers]
    },
    optionsRecommendedByPastor() {
      return [...this.convertedindividualEditFormOptions.pastorsByCamp]
    },
    optionsMembers() {
      let newFormOptions = []
      if (this.campMembers) {
        newFormOptions = this.campMembers.map((x) => {
          return {
            text: x.name,
            value: x.ind_key,
          }
        })
      }
      return newFormOptions
    },
    optionsStates() {
      return [...this.convertedProspectFormOptions.states]
    },
    optionsCountries() {
      return [...this.convertedProspectFormOptions.countries]
    },
    optionsPreferredName() {
      return [
        { text: this.translations.tcFormal, value: 'F' },
        { text: this.translations.tcInformal, value: 'I' },
      ]
    },
    showSpouse() {
      if (this.individualProfile.widow_flag) {
        return false
      }

      const result =
        this.convertedindividualEditFormOptions.memberTypes.filter(
          (type) => type.value === this.individualProfile.spouse_member_type_key
        ).length > 0

      return !result
    },
    title() {
      let result = ''
      switch (this.individual.individual_type) {
        case 'Member':
          result = this.translations.tcEditMemberDetailsTitle
          break
        case 'Non-Member':
          result = this.translations.tcEditNonMemberDetailsTitle
          break
        case 'Candidate':
          result = this.translations.tcEditCandidateDetailsTitle
          break
        default:
          result = ''
      }
      return result
    },
  },
  components: {
    pageBody: pageBody,
    ToggleArrow: ToggleArrow,
    iTooltip: iTooltip,
    AppDatePicker: AppDatePicker,
    PersonSearch: PersonSearch,
    ChurchSearch: ChurchSearch,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.form-group-label {
  margin-bottom: 0;
  color: #636363;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 22px;
}

h3 {
  line-height: 1;
}

.church_image {
  background-size: 100% 100%;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
